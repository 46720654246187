import React from 'react'
import { Link as  RouterLink } from 'react-router-dom'
import Animation from "./../Animation/Main";
import { toAbsoluteUrl } from "../../../../../../_metronic";
import {ON_LOGIN, ON_REGISTRATION} from "../../../../../router/routesMap";
import Link from "@material-ui/core/Link";

const title = "SKUify Shows You Exactly How Much Money You Are Making (Or Not), Where\u00A0Your\u00A0Inventory Is & How Much It's Worth At Any Point in Time..."

function Banner() {
  return (
    <>
      <section className="banner_section">
        <div className="container">
          <Animation />
        </div>

        <div className="container">

          <div className="banner_text">
            <div className="ban_inner_text">
              <h1 data-aos="fade-up" data-aos-duration="3000">
                {title}
              </h1>
              <p>
                Manage Your Amazon Business Without Spreadsheets, Guesswork Or Hard To Use, Clunky, Out-Of-Date Software!
              </p>
            </div>

            <div className="auth" data-aos="fade-up" data-aos-duration={1500}>

              <a href="#pricing-anchor" className="btn btn_main">
                START FREE TRIAL
              </a>

              <span>
                Already using SKUify? <RouterLink to={ON_LOGIN}>Sign in</RouterLink>
              </span>
            </div>


            {/*<form data-aos="fade-up" data-aos-duration={1500}>*/}
              {/*<button className="btn btn_main">*/}
                {/*SIGN UP*/}
              {/*</button>*/}
              {/*<span>*/}
                {/*Already using software? <Link to="#">Sign in</Link>*/}
              {/*</span>*/}
            {/*</form>*/}
          </div>
          <div className="banner_images" data-aos="fade-up" data-aos-duration={1500}>
            <a href="#pricing-anchor">
              <img src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/banner-top-new.png")} alt="image" />
            </a>
          </div>
        </div>
      </section>

    </>
  )
}

export default Banner
